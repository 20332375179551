enum PaywallScreens {
  // App
  AppSignUp = 'app__signup',
  AppBanner = 'app__banner',
  AppHeaderAddRoster = 'new_header__add_roster',
  AppHeader = 'new__header',
  AppAccount = 'app__account',
  // Quiz List
  QuizListBanner = 'quiz_list__banner',
  QuizListBannerDeleted = 'quiz_list__banner_deleted',
  QuizListAddFolder = 'quiz_list__addfolder',
  QuizListAddQuiz = 'quiz_list__addquiz',
  QuizListImportQuiz = 'quiz_list__importquiz',
  QuizListCopyQuiz = 'quiz_list__copyquiz',
  QuizListMoveItem = 'quiz_list__moveitem',
  QuizListMergeQuiz = 'quiz_list__mergequiz',
  QuizListDeleteQuiz = 'quiz_list__deletequiz',
  QuizListRestoreQuizFolder = 'quiz_list__restorequizfolder',
  QuizListLimitReached = 'quiz_list__limitreached',
  QuizListLimitBanner = 'quiz_list__limitbanner',
  QuizListAccessLocked = 'quiz_list__accesslocked',
  // Quiz Edit
  QuizEditAddAnswerImage = 'quiz_edit__add_answer_image',
  QuizEditAddExplanationImage = 'quiz_edit__add_explanation_image',
  QuizEditAddMathEquation = 'quiz_edit__math_equation',
  QuizEditAddGradingWeight = 'quiz_edit__add_grading_weight',
  QuizEditEmbedAudioVideo = 'quiz_edit__embed_audio_video',
  // Quiz Options
  LaunchQuizQuickQuestionOneAttempt = 'launch_quiz__quick_question__one_attempt',
  // Rooms
  RoomsBanner = 'rooms__banner',
  RoomsAddRoom = 'rooms__add_room',
  RoomsAddMoreRoom = 'rooms_add_more_room',
  RoomsMaxRooms = 'rooms_add_more_room',
  RoomsShareRoom = 'rooms__share_room',
  RoomsAddRoster = 'rooms__add_roster',
  RoomsRosterMaxStudent = 'rooms__roster_max_student',
  RoomsRosterAddOneStudent = 'rooms__roster_add_one_student',
  RoomsRosterAddManyStudents = 'rooms__roster_add_many_students',
  // Reports
  ReportListLimitEnd = 'report_list__limit_end',
  ReportListLimitStart = 'report_list__limit_start',
  ReportEmailStudents = 'report__email_students',
  ReportAnswerKey = 'report__answer_key',
  // Report Options
  ReportOptionsCompleteResultsIncludeMCAnswerContent = 'report_options__complete_results__include_mc_answer_content',
  ReportOptionsIndividualStudentsIncludeExplanations = 'report_options__individual_students__include_explanations',
  ReportOptionsIndividualStudentsIncludeCorrectAnswers = 'report_options__individual_students__include_correct_answers',
  ReportOptionsResultsSummaryIncludeExplanations = 'report_options__results_summary__include_explanations',
  ReportOptionsAnswerKeyIncludeExplanations = 'report_options__answer_key__include_explanations',
  // Results
  ResultsAddManualGrade = 'results__add_manual_grade',

  // Discovery

  DiscoveryImportQuiz = 'discovery__importquiz',

  // Shared Libraries
  SharedLibraryAddLibrary = 'shared_library__add_library',
  SharedLibraryListAddQuiz = 'shared_library_list__add_quiz',
  SharedLibraryListAddFolder = 'shared_library_list__add_folder',
  SharedLibraryListImportQuiz = 'shared_library_list__import_quiz',
  SharedLibraryListRenameFolder = 'shared_library_list__rename_folder',
  SharedLibraryListDuplicateQuiz = 'shared_library_list__duplicate_quiz',
  SharedLibraryListDownloadQuiz = 'shared_library_list__download_quiz',
  SharedLibraryListMoveItems = 'shared_library_list__move_items',
  SharedLibraryEditQuiz = 'shared_library__edit_quiz',
  SharedLibraryLaunchQuiz = 'shared_library_list__launch_quiz',
  SharedLibraryListPurgeItem = 'shared_library_list__purge_item',
  SharedLibraryListRestoreItem = 'shared_library_list__restore_item',
  SharedLibraryListBanner = 'shared_library__list_banner',

  // Activity
  PauseActivity = 'activity__pause_activity',
}

enum RoomTypes {
  RosteredRoom = 'rostered_room',
  OpenRoom = 'open_room',
}

enum LaunchSources {
  LaunchScreen = 'launch',
  Question = 'question',
}

export enum FinishRoute {
  ResultsPage = 'results_page',
  StartNewActivity = 'start_new_activity',
  Countdown = 'countdown',
}

enum EventTypes {
  SignUp = 'sign_up',
  LaunchQuiz = 'launch_quiz',
  LaunchSpaceRace = 'launch_space_race',
  LaunchShortAnswer = 'launch_short_answer',
  LaunchMultipleChoice = 'launch_multiple_choice',
  LaunchTrueFalse = 'launch_true_false',
  LaunchExitTicket = 'launch_exit_ticket',
  LaunchVote = 'launch_vote',
  FinishActivity = 'finish_activity',
  PauseActivity = 'pause_activity',
  ResumeActivity = 'resume_activity',
  GenerateReport = 'generate_report',
  PaywallTouch = 'paywall_touched',
  Purchase = 'purchase',
  ApplyLicense = 'apply_license',
  GradeModified = 'grade_modified',
  CreateQuiz = 'create_quiz',
  AddQuizModal = 'add_quiz_modal',
  AddQuiz = 'add_quiz',
  AiUsageLimit = 'ai_usage_limit',
  AiFeedbackForm = 'ai_feedback_form',
  ContactSales = 'contact_sales',

  // Quiz Editor
  QuestionSaved = 'question_saved',
  EmbedVideo = 'embed_video',

  // AI Question Generation
  GenerateAIQuestions = 'ai_generate_questions',
  ImportFromTextWithAI = 'ai_import_from_text',

  CreateFolder = 'create_folder',

  // Workspaces
  CreateWorkspace = 'create_workspace',
  JoinWorkspace = 'join_workspace',

  DeleteQuestion = 'delete_question',
  BulkAddQuestions = 'bulk_add_questions',

  // Rooms and Rosters
  DeleteRoster = 'delete_roster',
  CreateRoom = 'create_room',
  UpdateRoomRoster = 'update_room_roster',
  DeleteRoom = 'delete_room',
  ImportRoomRoster = 'import_room_roster',

  // New Stripe Subscription
  CreateCheckoutSession = 'create_checkout_session',
  CreatePortalSession = 'create_portal_session',
  ViewPricingTable = 'view_pricing_table',

  FeatureUse = 'feature_use',
}

enum QuestionTypes {
  MultipleChoice = 'multiple_choice',
  ShortAnswer = 'short_answer',
  TrueFalse = 'true_false',
}

enum ClientApps {
  AndroidApp = 'Android App',
  iOSApp = 'iOS App',
  ShowbieIOSApp = 'Showbie iOS App',
  ShowbieWebApp = 'Showbie Web App',
  WebApp = 'WebApp',
}

export {
  EventTypes,
  LaunchSources,
  RoomTypes,
  QuestionTypes,
  ClientApps,
  PaywallScreens,
};
